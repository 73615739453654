import * as React from 'react';
import TarpoAutocomplete from "../../form-fields/TarpoAutocomplete";
import {Field} from "formik";
import { i18n } from "../../config/i18n";
import {OverlayTrigger, Popover} from 'react-bootstrap'
import {useContext, useState} from "react";
import {ITarpo, IUser} from "../../interfaces";
import {UserContext} from "../../contexts/UserContext";

interface Props {
    index: any;
    tarpo: ITarpo;
    setFieldValue: any;
    submitForm: any;
    arrayHelpers: any;
    expandTarpo: any;
    getTarpoChain: any;
}

const Tarpo: React.FunctionComponent<Props> = props => {
    const [chain, setChain] = useState([]);
    const {index, tarpo, setFieldValue, submitForm, arrayHelpers, expandTarpo, getTarpoChain} = props;
    const user = useContext<IUser>(UserContext)

    const renderChainPopover = (props) => (
            <Popover key={`chain-popover-${tarpo.code}`} {...props} >
                <Popover.Header as="h3">{i18n.t('service_block')} {tarpo.code}</Popover.Header>
                <Popover.Body>
                    <div>
                        {chain.map((code) => (
                            <div key={`chain_el_${code.code}`}>
                                <span className="font-weight-bold me-2">{code.code}</span>
                                <span>{code.text}</span>
                            </div>
                        ))}
                    </div>
                    <button className="btn btn-secondary m-2"
                            onClick={() => {expandTarpo(tarpo, index)}}>{i18n.t('expand_tarpo')}</button>
                </Popover.Body>
            </Popover>
    )

    return (
        <div key={index}>
            <div className="code_input_div row">
                <div className="col-lg-6 pe-0">
                    <div className={'input-group ' + (!tarpo.active ? 'disabled' : '')}>
                        <div className="col-sm-5 px-0">
                            <TarpoAutocomplete
                                name={`tarpos[${index}].code`}
                                value={tarpo && tarpo.code || ""}
                                setValue={setFieldValue}
                                valid={tarpo.valid}
                                tariff={tarpo.tariff.toUpperCase()}
                                submitForm={submitForm}
                            />
                            <span className="fa fa-check removetag me-2"
                                    title={i18n.t(`${tarpo.active ? 'de' : ''}activate_code`)}
                                    onClick={async () => {
                                        setFieldValue(`tarpos[${index}].active`, !tarpo.active);
                                        await Promise.resolve();
                                        submitForm();
                                    }}/>
                        </div>
                        <div className="col-sm-2 px-0" title={i18n.t('tarpo_number')}>
                            <Field type="float"
                                   name={`tarpos[${index}].number`}
                                   id={`tarpos[${index}].number`}
                                   className={"form-control"}
                                   value={tarpo && tarpo.number || ''}
                            />
                        </div>
                        <div className="col-sm-2 px-0" title={tarpo.tariff_text}>
                            <Field type="text"
                                   name={`tarpos[${index}].tariff`}
                                   id={`tarpos[${index}].tariff`}
                                   className={`form-control${tarpo.tariff_text === 'invalid_tariff' ? ' input-invalid' : ''}`}
                                   value={tarpo && tarpo.tariff || '001'}
                                   onKeyDown={(event) => {
                                       if (event.key === 'Enter') {
                                           event.preventDefault(); // Prevent form submission to avoid adding empty tarpo and thus changing tariff back to '001'.
                                       }
                                   }}
                            />
                        </div>
                        <div className="col-sm-3 px-0" title={i18n.t('amount')}>
                            <Field type="float"
                                   name={`tarpos[${index}].amount`}
                                   id={`tarpos[${index}].amount`}
                                   className={"form-control"}
                                   value={tarpo && tarpo.amount || ''}
                            />
                            <span
                                className="fa fa-remove removetag"
                                onClick={async () => {
                                    arrayHelpers.remove(index);
                                    await Promise.resolve();
                                    submitForm();
                                }}
                                title={i18n.t('delete_code')}/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 code_description">
                    { user.admin && <div title={tarpo.date} className="badge bg-light me-1">{tarpo.day}</div> }
                    {tarpo.url === '' ?
                        <span>{tarpo.text}</span>  :  <a href={tarpo.url} target={tarpo.code}>{tarpo.text}</a>
                    }
                    {tarpo.has_chain && tarpo.code != '' &&
                        <OverlayTrigger
                            placement="right"
                            trigger="click"
                            overlay={renderChainPopover}
                            rootClose={true}
                        >
                            <span
                                className="fa fa-expand pull-right pseudoLink"
                                onClick={() => {
                                    getTarpoChain(tarpo, index, async (data) => {
                                        console.log("obtained chain: " + tarpo.code)
                                        setChain(data['chain']);
                                    })
                                }}
                                title={i18n.t('expand_tarpo')}/>
                        </OverlayTrigger>
                    }
                </div>
            </div>
        </div>
    )
};

export default Tarpo
