import * as React from 'react';
import AdministrativeData from "../components/patient-case/AdministrativeData"
import Diagnoses from "../components/patient-case/Diagnoses"
import {PcsGroupService} from "../services/PcsGroupService";
import {Formik, Form} from 'formik';
import Procedures from "../components/patient-case/Procedures";
import {IPcsGrouperResults, IPatientCase, IPcs} from "../interfaces";
import {PATIENT_CASE_DEFAULT_STATE} from "../utils/patientCaseState";
import Drugs from "../components/patient-case/Drugs";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormButtons from "../components/FormButtons";
import { History } from 'history';
import PcsGroupResult from "../components/patient-case/PcsGroupResult";
import PcsSystemSelection from "../components/PcsSystemSelection";
import {useEffect, useState, useContext, useRef} from "react";
import {LocaleContext} from "../contexts/LocaleContext";
import Tarpos from "../components/patient-case/Tarpos";
import Alert from "../components/Alert";
import { i18n } from "../config/i18n";

interface Props {
    id: number;
    systems: IPcs[]
    history: History;
    urlEncodedPc: string;
    currentSystemId: string;
}

const PcsSingleCaseGroupForm: React.FunctionComponent<Props> = props => {
    const {systems, currentSystemId} = props;
    const systemsById = Object.fromEntries(systems.map(x => [x.id, x]));
    const locale = useContext(LocaleContext)
    i18n.locale = locale;
    const [patientCase, setPatientCase] = useState<IPatientCase>(PATIENT_CASE_DEFAULT_STATE.patient_case)
    const [currentSystem, setCurrentSystem] = useState<IPcs>(systemsById[currentSystemId] || systems[0])
    const [grouperResults, setGrouperResults] = useState<IPcsGrouperResults | undefined>( undefined)
    const pcValuesRef = useRef<IPatientCase | undefined>(undefined)
    const loadByUrl = useRef<boolean>(true)

    useEffect(() => {
        if(grouperResults === undefined && currentSystem) {
            group();
        }
    }, [grouperResults]);

    useEffect(() => {
        initGroup()
    }, [currentSystem]);

    function changePatientCaseValue(name, value) {
        setPatientCase(prevState => ({
                ...prevState,
                [name]: value
            }
        ))
    }

    // initialize grouping. this will call group indirectly using useEffect after grouper result state has updated.
    function initGroup() {
        setGrouperResults(undefined)
    }

    function group() {
        const {history, id, urlEncodedPc} = props;

        PcsGroupService.create({patient_case: pcValuesRef.current},
            loadByUrl.current ? urlEncodedPc : null, id, currentSystem.id, locale)
            .then(response => {
                if (!response.ok && response.problem !== 'CANCEL_ERROR') {
                    toast('Something went wrong', {type: 'error'});
                }
                return response;
            })
            .then(response => response.data)
            .then((data) => {
                if (data && data.patient_case) {
                    const url = `?locale=${locale}&pc=${data.patient_case.url_encoded}&system_id=${currentSystem.id}`
                    history.push(url);
                    data.patient_case.procedures.push({code: '', side: '', date: '', active: true});
                    data.patient_case.secondary_diagnoses.push({code: '', active: true});
                    data.patient_case.drugs.push({code: '', dose: 0, active: true});
                    data.patient_case.age_selection = data.patient_case.age_years > 0 ? '1' : '0';
                    setPatientCase(data.patient_case);
                    setGrouperResults(data.grouper_results)
                    loadByUrl.current = false
                }
            });
    }

    function reset(){
        setPatientCase(PATIENT_CASE_DEFAULT_STATE.patient_case);
    }

    return (
        systems.length > 0 ?
            <Formik
                initialValues={patientCase}
                enableReinitialize={true}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={(values, {setSubmitting}) => {
                    pcValuesRef.current = values;
                    initGroup();
                    setSubmitting(false);
                }}
            >
                {({isSubmitting, values, submitForm, setFieldValue, errors}) => (
                    <Form autoComplete="off">
                        <div className="row vertical-spacer">
                            <div className="col-lg-6 pr-0">
                                <PcsSystemSelection
                                    systems={systems}
                                    currentSystem={currentSystem}
                                    changeSystem={(systemId) => {
                                        pcValuesRef.current = values;
                                        setCurrentSystem(systemsById[systemId])
                                    }}
                                />
                                <AdministrativeData
                                    originalAge={(patientCase && patientCase.age_years)}
                                    values={values}
                                    submitForm={submitForm}
                                    setFieldValue={setFieldValue}
                                    flags={{
                                        ageFlag: {valid: true, used: false},
                                        admWeightFlag: {valid: true, used: false},
                                        sexFlag: {valid: true, used: false},
                                        sepFlag: {valid: true, used: false},
                                        losFlag: {valid: true, used: false},
                                        hmvFlag: {valid: true, used: false},
                                        gestAgeFlag: {valid: true, used: false},
                                    }}
                                />
                                <Diagnoses
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    submitForm={submitForm}
                                    colorMeterEnabled={false}
                                    changeValue={changePatientCaseValue}
                                />
                                <Procedures
                                    procedures={values.procedures}
                                    setFieldValue={setFieldValue}
                                    submitForm={submitForm}
                                    colorMeterEnabled={false}
                                />
                                <Tarpos
                                    title={i18n.t('services')}
                                    tarpos={values.tarpos}
                                    setFieldValue={setFieldValue}
                                    submitForm={submitForm}
                                    id="all"
                                    locale={locale}/>
                                <Drugs
                                    errors={errors}
                                    drugs={values.drugs}
                                    setFieldValue={setFieldValue}
                                    submitForm={submitForm}
                                />
                            </div>
                            <div className="col-lg-6">
                                <PcsGroupResult grouperResults={grouperResults}/>
                            </div>
                        </div>
                        <FormButtons isSubmitting={isSubmitting} reset={reset} id={props.id} patientCase={values}/>
                    </Form>
                )}
            </Formik> :
            <Alert type="info">
                {i18n.t('no_pcs_available')}
            </Alert>
    );
}

export default  PcsSingleCaseGroupForm;
